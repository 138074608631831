<template>
  <div class="banner-component">
    <!-- 这个地方的代码并不是容器的感觉，更像一个点。一辆火车头拉着不断变换车厢号的N节车厢，行驶在不断向后运动的无限长轨道（屏幕）上，造成了行驶在原地的错觉？？ -->
    <div class="locomotive" ref="locomotive" :style="{'transform': `translateX(${offset}px)`}"> 
        <slot :data="items" :now-index="index % items.length"></slot>
    </div>
    <div class="tablist-wrapper">
        <ul>
            <li v-for="(data, i) in items" :key="i" :class="i === (index % items.length) ? 'active' : null">
                <div class="pointer" @click="activeIndex(i, true)"></div>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
import {throttle} from "lodash";
export default {
name: 'TCarousel',
props: {
    list: Array,
    timeInterval: {
        type: Number,
        default() {
            return 1000;
        }
    }
},
data() {
    return {
        items: [],
        width: 995,
        index: 0,
        offset: 0,
        interval: null,
    }
},
mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
},
methods: {
    handleResize() {
        let windowWidth = window.innerWidth
        if (windowWidth >= 1441) {
            this.width = 1265
        }else if (windowWidth <= 1068) {
            this.width = 704
        }else if (windowWidth <= 734) {
            this.width = 289
        } else {
            this.width = 995
        }
        this.offset = this.index * (0 - this.width);
    },
    // throttle: throttle(fn, 500, {'trailing': true}),
    activeIndex(index, isClear = false) {
        if (isClear && this.interval) {
            clearInterval(this.interval)
            this.interval = null
        }
        let length = this.items.length
        let startPoint = this.index % length
        console.log(index, startPoint)
        if (startPoint < index) {
            let step = index - startPoint;
            // let count = 1;
            // let step2 = step;
            // let interval = setInterval(() => {
            //     if (count > step) {
            //         clearInterval(interval)
            //         interval = null
            //         return false; // 在计时器中 clear之后 还是会把剩下的代码执行完，所以要用return 停止
            //     }
            //     this.right()
            //     count++;
            //     step2 = (this.index % length) - index;
            // }, Math.floor((1000 / step2) * 0.5) )

            let offset = 0;
            for (let i = 0; i < step; i++) {
                offset = this.right(false, true)
            }
            this.offset = offset;
        }
        if (startPoint > index) {
            let step = startPoint - index;
            // let count = 1;
            // let step2 = step;
            // let interval = setInterval(() => {
            //     if (count > step) {
            //         clearInterval(interval)
            //         interval = null
            //         return false;
            //     }
            //     this.left()
            //     count++;
            //     step2 = (this.index % length) - index;
            // }, Math.floor((1000 / step2) * 0.5) )

            let offset = 0;
            for (let i = 0; i < step; i++) {
                offset = this.left(false, true)
            }
            this.offset = offset;
        }
    },
    left(isClear = false, isStop = false) {
        if (isClear && this.interval) {
            clearInterval(this.interval)
            this.interval = null
        }
        let current = this.index;

        let offset = (current - 1) * (0 - this.width); // 当前位置 - 1，等于往左移动一位
        if (!isStop) {
            this.offset = offset;
        }

        this.index = current - 1;

        let nowIndex = this.index;

        let length = this.items.length;

        setTimeout(() => {
            for (let i = 0; i < length; i++) {
                if (this.items[i].step == nowIndex) {
                    if (i == 0) { // 则说明它是第一个元素，它的左侧元素即为这个数组最后一个元素
                        // 此时将最后一个元素的值设置成 第一个元素 值 减去 1
                        this.items[length - 1].step = nowIndex - 1;
                    } else { // 如果不是第一个元素则说明它是第一个元素之后的元素，获取这个元素左边（前一个）元素值 减去 1
                        this.items[i - 1].step = nowIndex - 1;
                    }
                }
            }
        }, 300)
        
        return offset;
    },
    right(isClear = false, isStop = false) {
        if (isClear && this.interval) {
            clearInterval(this.interval)
            this.interval = null
        }
        let current = this.index;
        // console.log(current)

        let offset = (current + 1) * (0 - this.width);
        if (!isStop) {
            this.offset = offset
        }

        this.index = current + 1;

        let nowIndex = this.index;

        let length = this.items.length;
        setTimeout(() => {
            for (let i = 0; i < length; i++) {
                if (this.items[i].step == nowIndex) {
                    if (i == length - 1) { // 则说明它是最后一个元素，它的右侧元素即为这个数组第一个元素
                        // 此时将第一个元素的值设置为 最后一个元素 值 加 1
                        this.items[0].step = nowIndex + 1;
                    } else {
                        this.items[i + 1].step = nowIndex + 1;
                    }
                }
            }
        }, 300)
        return offset;
    },
    
    init() {
        clearInterval(this.interval)
        this.interval = null;

        this.items = JSON.parse(JSON.stringify(this.list))
        for (let i = 0; i < this.items.length; i++) {
            if (i == this.items.length - 1) {
                this.$set(this.items, i, {...this.items[i], step: -1})
            } else {
                this.$set(this.items, i, {...this.items[i], step:  i})
            }
        }
        // let locomotive = this.$refs['locomotive']
        this.interval = setInterval(() => {
            this.right()
        }, this.timeInterval)
    }
},
beforeDestroy() {
    if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
    }
    window.removeEventListener('resize', this.handleResize)
}
}
</script>

<style lang="scss" scoped>

.banner-component {
    overflow: hidden;
    height: 561.13px;
    margin: 0 auto;
    position: relative;
}
.locomotive {
    width: 995px;
    margin: 0 auto;
    position: relative;
    transform: translateX(0px);
    transition: transform  1s cubic-bezier(0.645, 0.045, 0.355, 1);
    will-change: transform;
}
.banner-item {
    height: 523.13px;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0 7.5px;
    /*-abs: max(var(--index), var(--index) * -1);
    --index-0-1: clamp(0, var(--abs), 1);
    --index-1-1: clamp(-1, var(--index), 1);*/
    transform: translateX(calc(var(--index) * 995px));
    transition: opacity 1s;
}
.tablist-wrapper {
    position: absolute;
    left: 50%;
    bottom: 7px;
    transform: translateX(-50%);
    text-align: center;

    ul {
        margin: 0;
        display: inline-flex;
        list-style: none;
        justify-content: center;

        li {
            list-style: none;
            margin: 0 7px;
            width: 8px;
            height: 8px;

            .pointer {
                cursor: pointer;
                width: 100%;
                height: 100%;
                transition: background-color 100ms linear;
                background-color: rgba(0, 0, 0, 0.42);
                border-radius: 50%;
            }
        }

        .active {
            .pointer {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }
    }
}

@media (min-width: 1441px) {
    .banner-component {
        height:705.25979px
    }
    .locomotive {
        width:1265px
    }
    .banner-item {
        height:667.25979px;
        transform: translateX(calc(var(--index) * 1265px));
    }
}

@media only screen and (max-width: 1068px) {
    .banner-component {
        height:405.79359px
    }
    .locomotive {
        width:704px
    }
    .banner-item {
        height:367.79359px;
        transform: translateX(calc(var(--index) * 704px));
    }
}

@media only screen and (max-width: 734px) {
    .banner-component {
        height:533px
    }
    .locomotive {
        width:289px
    }
    .banner-item {
        height:495px;
        transform: translateX(calc(var(--index) * 289px));
    }
}

</style>